const quotes = [
    {
        quote: "I skate to where the puck is going to be, not where it has been.",
        quoting: "Wayne Gretzky"
    },
    {
        quote: "We aim above the mark to hit the mark.",
        quoting: "Ralph Waldo Emerson"
    },
    {
        quote: `Creativity is just connecting things. When you ask creative people how they did something,
        they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them
        after a while. That's because they were able to connect experiences they've had and synthesize new things.`,
        quoting: "Steve Jobs"
    },
    {
        quote: "The difference between try and triumph is a little umph.",
        quoting: "Marvin Phillips"
    },
    {
        quote: `I don’t wanna be Jordan, I don’t wanna be Magic, I don’t wanna be Bird or Isiah.I don’t wanna be any
        of those guys. When my career’s over, I want to look in the mirror and say I did it my way.`,
        quoting: "Allen Iverson"
    }
]

export default quotes;