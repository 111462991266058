const secretPageIt = {
    "secret": {
        "title": "Hai trovato un segreto!",
        "description": "Complimenti!"
    }
}

const secretPageEn = {
    "secret": {
        "title": "You found a secret!",
        "description": "Well done!"
    }
}

export {secretPageIt, secretPageEn};